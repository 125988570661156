define("discourse/plugins/discourse-calendar/discourse/components/discourse-post-event/invitees", ["exports", "@glimmer/component", "@ember/service", "discourse/helpers/d-icon", "discourse-i18n", "discourse/plugins/discourse-calendar/discourse/components/discourse-post-event/invitee", "@ember/component", "@ember/template-factory"], function (_exports, _component, _service, _dIcon, _discourseI18n, _invitee, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class DiscoursePostEventInvitees extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "modal", [_service.service]))();
    #modal = (() => (dt7948.i(this, "modal"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    get hasAttendees() {
      return this.args.event.stats.going > 0;
    }
    get statsInfo() {
      return this.args.event.stats.going;
    }
    get inviteesTitle() {
      return (0, _discourseI18n.i18n)("discourse_post_event.models.invitee.status.going_count", {
        count: this.args.event.stats.going
      });
    }
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#unless @event.minimal}}
          {{#if @event.shouldDisplayInvitees}}
            <section class="event__section event-invitees">
              <div class="event-invitees-avatars-container">
                <div class="event-invitees-icon" title={{this.inviteesTitle}}>
                  {{icon "users"}}
                  {{#if this.hasAttendees}}
                    <span class="going">{{this.statsInfo}}</span>
                  {{/if}}
                </div>
                <ul class="event-invitees-avatars">
                  {{#each @event.sampleInvitees as |invitee|}}
                    <Invitee @invitee={{invitee}} />
                  {{/each}}
                </ul>
              </div>
            </section>
          {{else}}
            {{#unless @event.isStandalone}}
              <section class="event__section event-invitees no-rsvp">
                <p class="no-rsvp-description">{{i18n
                    "discourse_post_event.models.invitee.status.going_count.other"
                    count="0"
                  }}</p>
              </section>
            {{/unless}}
          {{/if}}
        {{/unless}}
      
    */
    {
      "id": "Fzr6WwfD",
      "block": "[[[1,\"\\n\"],[41,[51,[30,1,[\"minimal\"]]],[[[41,[30,1,[\"shouldDisplayInvitees\"]],[[[1,\"        \"],[10,\"section\"],[14,0,\"event__section event-invitees\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"event-invitees-avatars-container\"],[12],[1,\"\\n            \"],[10,0],[14,0,\"event-invitees-icon\"],[15,\"title\",[30,0,[\"inviteesTitle\"]]],[12],[1,\"\\n              \"],[1,[28,[32,0],[\"users\"],null]],[1,\"\\n\"],[41,[30,0,[\"hasAttendees\"]],[[[1,\"                \"],[10,1],[14,0,\"going\"],[12],[1,[30,0,[\"statsInfo\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"            \"],[13],[1,\"\\n            \"],[10,\"ul\"],[14,0,\"event-invitees-avatars\"],[12],[1,\"\\n\"],[42,[28,[31,3],[[28,[31,3],[[30,1,[\"sampleInvitees\"]]],null]],null],null,[[[1,\"                \"],[8,[32,1],null,[[\"@invitee\"],[[30,2]]],null],[1,\"\\n\"]],[2]],null],[1,\"            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],[[[41,[51,[30,1,[\"isStandalone\"]]],[[[1,\"          \"],[10,\"section\"],[14,0,\"event__section event-invitees no-rsvp\"],[12],[1,\"\\n            \"],[10,2],[14,0,\"no-rsvp-description\"],[12],[1,[28,[32,2],[\"discourse_post_event.models.invitee.status.going_count.other\"],[[\"count\"],[\"0\"]]]],[13],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null]],[]]]],[]],null],[1,\"  \"]],[\"@event\",\"invitee\"],false,[\"unless\",\"if\",\"each\",\"-track-array\"]]",
      "moduleName": "/opt/bitnami/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-calendar/discourse/components/discourse-post-event/invitees.js",
      "scope": () => [_dIcon.default, _invitee.default, _discourseI18n.i18n],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = DiscoursePostEventInvitees;
});